import React from 'react';
import { KeyboardLayout, KeyboardLayoutDisplayName } from 'shared/types/keyboardShortcuts';

import { Dialog } from './Dialog';

export const ResetKeyboardLayoutDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  keyboardLayout,
}: { isOpen: boolean; onConfirm: () => void; onCancel: () => void; keyboardLayout: KeyboardLayout }) => {
  const keyboardShortcutDisplayName = KeyboardLayoutDisplayName[keyboardLayout];

  if (isOpen) {
    return (
      <div>
        <Dialog
          id="reset-keyboard-layout"
          title={`Reset all ${keyboardShortcutDisplayName} shortcuts?`}
          subtitle={`Are you sure you want to reset your shortcuts to the ${keyboardShortcutDisplayName} default?`}
          actionTitle="Reset all shortcuts"
          cancelTitle="Cancel"
          redActionButton
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }

  return null;
};
